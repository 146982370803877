<template>
    <div class="container">
        <div class="row align-items-center justify-content-center" style="min-height: 100vh">
            <div class="col-12 col-sm-auto" style="max-width: 500px">

                <div class="card bg-transparent pt-5 pb-5 text-center">

                    <div class="card-body ">
                        <img class="icon mb-3" src="../assets/images/fail.png">

                        <div v-html="template"></div>
                    </div>


                </div>

            </div>
        </div>

    </div>
</template>

<script>

  export default {
    name: 'Error',
    components: {},
    data() {
      return {message: "", template: ""}
    },
    created() {
      this.message = this.$route.query.type;
      switch (this.message) {
        case 'expired' :
          this.template = `<div class="sub-title text-center">Time expired, Please try again.</div>`;
          break;
        case 'already_register' :
          this.template = `<div class="sub-title text-center">Already register, Please try again.</div>`;
          break;
        default:
          this.template = `<div class="sub-title text-center">Please try again.</div>`;
          break;
      }
    },
    methods: {}
  }
</script>


<style lang="scss" scoped>
    @import "../assets/css/colors";

    .icon {
        max-height: 100px;
    }
</style>
